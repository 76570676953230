<template>
  <contact-form
    :header="$t('contactUs.header')"
    :subheader="$t('contactUs.subheader')"
    source="ContactUs"
    show-contacts
  />
</template>

<script>
import ContactForm from '@/views/components/ContactForm/Index.vue';

export default {
  name: 'ContactUs',
  components: { ContactForm },
};
</script>
